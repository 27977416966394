import React from "react";
import {about} from "../const";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import SideBar from "../master/SideBar";
import STRATEGICPLAN20212023 from "../../assets/about/Strategic Vision-final 27.07.2021.pdf";

const EmploymentContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Work At St Maroun’s College" variant="h2"/>
                <p>
                    The College strives to achieve educational excellence and recognizes that staff play an important
                    role in the lives of its students and community.
                </p>

                <p>
                    If you are passionate and committed to student learning and welfare with a background in teaching
                    primary or secondary, we welcome your enquiry.
                </p>

                <PageTitle title="Expressions of Interest" variant="h3"/>
                <p>
                    For expressions of Interest please send the following documents to <a
                    href="mail:employment@stmarouns.nsw.edu.au">
                    employment@stmarouns.nsw.edu.au
                </a>
                    <p></p>
                    <ol>
                        <li>1. Cover letter
                        </li>
                        <li>2. Resume
                        </li>
                    </ol>
                </p>

                <PageTitle title="Current Openings" variant="h2"/>
                <p>
                    Casual teachers - Primary and secondary.
                </p>

                <PageTitle title="Respect At Work" variant="h2"/>
                <p>
                    Everyone deserves to be safe, supported and respected at work.
                </p>
                <p>
                    Sexual harassment constitutes a trespass, not only against the dignity of each person, but also
                    against the law.
                </p>
                <p>
                    Saint Maroun’s College seeks to prevent sexual harassment and discrimination at every level of the
                    organisation. Members of the College community are expected to support all policies, procedures and
                    other initiatives that are designed to preclude all forms of workplace sexual harassment,
                    discrimination or bullying.
                </p>
                <p>
                    Each person is encouraged to alert the appropriate authorities if they witness or experience sexual
                    harassment, discrimination or any other unlawful conduct.
                </p>
                <p>
                    Anyone experiencing harassment or discrimination will be supported and the College Whistleblower or
                    other reporting or complaint mechanisms will be taken seriously and managed ethically and
                    confidentially.
                </p>
                <p>
                    The College will provide ongoing training to enable people to recognise sexual harassment in all its
                    forms, empower them to speak about it, change their behaviour if required, and respond appropriately
                    in the moment to prevent potential repetition or escalation.
                </p>
                <p>
                    The College will strive for ethical responses. The College will support respectful transparency so
                    all might learn from incidences, continuously improve the College’s approach, and improve
                    accountability to staff, stakeholders, and the broader community.
                </p>

            </div>
            <SideBar items={about} title="About Us"/>
        </ContentWrapper>
    </div>
);

export default EmploymentContent;
